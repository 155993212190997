<template>
  <div class="backMenu">
    <div @click="move(topLeave.id,0)" @mouseover.self="mouseOverMove(0)">
      <span :class="overId == 0 ? 'mouse-over' : ''">{{
        topLeave.mechanismname
      }}</span>
    </div>
    <div
      v-for="item in tree"
      :key="item.id"
      @mouseover.self="mouseOverMove(item.id)"
      @click.stop="move(item.id,0)"
    >
      <span v-if="moveId == item.id" class="disableco">{{
        item.mechanismname
      }}</span>
      <span :class="overId == item.id ? 'mouse-over' : ''" v-else>{{
        item.mechanismname
      }}</span>

      <div
        v-for="val in item.platformmechanismlist"
        :key="val.id"
        @mouseover.self="mouseOverMove(val.id)"
        @click.stop="move(val.id,item.id)"
      >
        <span v-if="moveId == item.id || moveId == val.id" class="disableco">{{
          val.mechanismname
        }}</span>
        <span :class="overId == val.id ? 'mouse-over' : ''" v-else>{{
          val.mechanismname
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power", "getMenuList"); //权限vuex公共库
export default {
  data() {
    return {
      overId: -1,
    };
  },
  props: {
    tree: {
      type: Array,
      default: function () {
        return [];
      },
    },
    moveId: {
      type: String,
      default: function () {
        return;
      },
    },
    topLeave: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() {},
  methods: {
    // 鼠标移入变色
    mouseOverMove(val) {
      this.overId = val;
    },
    move(val,event) {
      if (val == this.moveId||event==this.moveId) {
        return;
      }
      this.$parent.move(val);
    },
  },
};
</script>

<style lang="scss" scoped>
// .move-list {
//   position: absolute;
//   background-color: #fff;
//   // border: 1px solid #f60;
//   // box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
//   top: 0;
//   width: 170px;
//   border-radius: 6px;
//   left: 168px;
//   height: 210px;
//   overflow-y: scroll;
//   display: block !important;
//   div {
//     display: block !important;
//     padding: 0 10px;
//     cursor: pointer;
//   }
// }
.backMenu{
  color: #333;
}
.mouse-over {
  color: #06b7ae;
}
.disableco {
  color: #ccc;
}
</style>
